import styled from 'styled-components'
import { useState } from 'react'
import emailjs from 'emailjs-com'
import Swal from 'sweetalert2'
import { LottieLoading } from '../LottieLoading'

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: start;
  label {
    margin: 13px 0px 4px 7px;
    color: #0179b4;
    font-weight: 600;
  }

  input,
  textarea,
  select {
    font-family: 'montserrat';
    color: #0179b4;
    padding: 7px 9px;
    border-radius: 20px;
    border: solid 2px #0179b4;
    width: 250px;
  }

  select {
    cursor: pointer;
  }

  @media (min-width: 750px) {
    input,
    textarea,
    select {
      width: 320px;
    }
  }
`
const StyledButton = styled.button`
  margin-top: 15px;
  padding: 5px;
  width: 100px;
  border-radius: 20px;
  border: solid 2px #0179b4;
  color: #fff;
  background-color: #0179b4;
  cursor: pointer;
  font-family: 'montserrat';
`

export const ContactForm = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [category, setCategory] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  function sendEmail(e) {
    e.preventDefault()
    setLoading(true)
    emailjs
      .sendForm(
        'service_rnphhab',
        'template_j22wodk',
        e.target,
        'iYt8RSBzIwruy4G0s'
      )
      .then((res) => {
        console.log(res)
        Swal.fire({
          title: 'Skickat!',
          text: 'Ditt mejl skickades, jag hör av mig så snart som möjligt!',
          icon: 'success',
          confirmButtonText: 'OK',
        })
        setName('')
        setEmail('')
        setCategory('')
        setMessage('')
      })
      .finally(() => setLoading(false))
      .catch((err) => {
        console.log(err)
        Swal.fire({
          title: 'Något gick fel!',
          text: 'Ditt mejl gick inte att skicka. Prova igen eller mejla je.fysioterapi@outlook.com',
          icon: 'error',
          confirmButtonText: 'OK',
        })
        setName('')
        setEmail('')
        setCategory('')
        setMessage('')
      })
  }

  return (
    <Form onSubmit={sendEmail}>
      <label htmlFor='name'>Fullständigt namn</label>
      <input
        id='name'
        type='text'
        name='name'
        value={name}
        placeholder='För- och efternamn...'
        onChange={(e) => setName(e.target.value)}
      />

      <label htmlFor='email'>Mejladress</label>
      <input
        id='email'
        name='user_email'
        type='email'
        placeholder='namn@exempel.se'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <label htmlFor='category'>Kategori</label>
      <select
        value={category}
        onChange={(e) => setCategory(e.target.value)}
        name='category'
      >
        <option disabled value=''>
          Välj
        </option>
        <option>Bokning</option>
        <option>Konsultation</option>
        <option>Workshop</option>
        <option>Annat</option>
      </select>
      <label htmlFor='message'>Meddelande</label>
      <textarea
        id='message'
        name='message'
        placeholder='Meddelande...'
        rows={10}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      {loading ? (
        <LottieLoading />
      ) : (
        <StyledButton type='submit'>Skicka</StyledButton>
      )}
    </Form>
  )
}
