import styled from 'styled-components'
import { Logo } from './Logo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons'

const FooterSection = styled.section`
  background-color: #0179b4;
  color: #fff;
  padding: 30px 30px;
`

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 30px;

  @media (min-width: 700px) {
    padding: 50px 50px;
    gap: 50px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    p {
      font-size: 18px;
      margin: 0;
    }
  }
`

const EmailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: start;
  gap: 10px;
`

const LocationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  line-height: 24px;
  align-self: start;
`

const OrgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  p {
    margin: 0;
  }
`
const LogoWrapper = styled.div`
  align-self: start;
`

const FooterInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  gap: 30px;
  width: 100%;
`

const AttributeCredit = styled.span`
font-size: 9px;
align-self: center;

`

export const Footer = () => {
  return (
    <FooterSection>
      <FooterContainer>
        <FooterInfoWrapper>
          <EmailWrapper>
            <FontAwesomeIcon icon={faEnvelope} color='white' size='2x' />
            <p>je.fysioterapi@outlook.com</p>
          </EmailWrapper>
          <LocationWrapper>
            <FontAwesomeIcon icon={faLocationDot} color='white' size='2x' />
            <p>
              Folkungplan 1
              <br />
              582 25 Linköping
              <br />
              Sweden
            </p>
          </LocationWrapper>
        </FooterInfoWrapper>
        <div className='mapouter'>
          <div className='gmap_canvas'>
            <iframe
              height='100%'
              width='100%'
              id='gmap_canvas'
              src='https://maps.google.com/maps?q=Medley%20Link%C3%B6ping%20sim&t=&z=13&ie=UTF8&iwloc=&output=embed'
              frameBorder='0'
              scrolling='no'
              marginHeight='0'
              marginWidth='0'
              title='google maps'
            />
            <br />
          </div>
        </div>
      </FooterContainer>
      <OrgWrapper>
        <p>JE Fysioterapi AB</p>
        <p>2022</p>
        <p>559367-1554</p>
        <AttributeCredit>Animation source: @EGO Creative Innovations & @Mark Boldy/ LottieFiles</AttributeCredit>
      </OrgWrapper>
    </FooterSection>
  )
}
