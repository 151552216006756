import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {
  WhiteSection,
  BlueCircle,
  GreySection,
  InnerContainer,
  SecondInnerContainer,
  CursiveText,
  TextArrowWrapper,
  ProfileCardContainer,
} from '../components/reusable/StyledComponents'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHandHoldingMedical,
  faVenus,
} from '@fortawesome/free-solid-svg-icons'
import { ProfileCard } from '../components/reusable/ProfileCard'
import { ContactForm } from '../components/reusable/ContactForm'
import Arrow from '../components/assets/arrow.png'

const IconInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (min-width: 550px) {
    flex-direction: row;
    justify-content: unset;
    width: 80%;
    height: 100px;
    text-align: inherit;
  }

  @media (min-width: 900px) {
    p {
      font-size: 20px;
    }
  }
`

const HomeInnerContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media (min-width: 750px) {
    width: 90%;
  }
`

export const Home = () => {
  return (
    <>
      <WhiteSection>
        <InnerContainer>
          <IconInfoWrapper>
            <BlueCircle>
              <FontAwesomeIcon
                icon={faHandHoldingMedical}
                size='2x'
                color='#fff'
              />
            </BlueCircle>

            <p>
              Fysioterapeutisk bedömning och skräddarsydd behandling av alla
              typer av akuta- och långvariga smärtbesvär.
            </p>
          </IconInfoWrapper>
          <IconInfoWrapper>
            <BlueCircle>
              <FontAwesomeIcon icon={faVenus} size='2x' color='#fff' />
            </BlueCircle>
            <p>
              Spetskompetens inom kvinnohälsa med fördjupad kunskap inom
              graviditetsrelaterade besvär, bäckenbottenbesvär samt träning
              under och efter graviditet.
            </p>
          </IconInfoWrapper>
        </InnerContainer>
      </WhiteSection>
      <GreySection>
        <SecondInnerContainer>
          <ProfileCardContainer>
            <ProfileCard />
          </ProfileCardContainer>
          <HomeInnerContainer>
            <p>
              Om du önskar boka en tid gör du det enklast via denna{' '}
              <a
                href='https://system.easypractice.net/book/jefysioterapi?cookie_fixed=1#choose-service'
                rel='noopener noreferrer'
              >
                länk
              </a>{' '}
              eller <Link to='/boka'>Boka</Link> i huvudmenyn. Du kan även mejla
              mig för att boka eller få rådgivning kring din situation och
              eventuell behandling.
              <br />
              <br />
              Mejla mig på je.fysioterapi@outlook.com eller skicka ett
              meddelande via formuläret nedan.
            </p>
          </HomeInnerContainer>

          <TextArrowWrapper>
            <CursiveText className='cursive-text'>
              Skicka ett meddelande så
              <br />
              återkommer jag kring hur jag
              <br />
              kan hjälpa dig!
            </CursiveText>
            <img src={Arrow} alt='arrow' />
          </TextArrowWrapper>
          <ContactForm />
        </SecondInnerContainer>
      </GreySection>
    </>
  )
}
