import styled from 'styled-components'

export const WhiteSection = styled.section`
  background-color: white;
  padding: 30px 30px;
  margin-top: 20px;
`

export const GreySection = styled.section`
  background-color: #f4f4f4;
  padding: 50px 30px 20px 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  a {
    text-decoration: none;
    color: #0179b4;
    font-weight: 600;
  }
`

export const BlueCircle = styled.div`
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background-color: #0179b4;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  @media (min-width: 550px) {
    margin-right: 20px;
  }
`

export const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export const SecondInnerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media (min-width: 750px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 900px) {
    p {
      font-size: 23px;
    }
  }
`

export const CursiveText = styled.p`
  font-family: 'caveat';
  font-size: 25px;
  transform: rotate(-8deg);
  margin: 0;

  @media (min-width: 900px) {
    &.cursive-text {
      font-size: 30px;
    }
  }
`
export const TextArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin: 20px;
    width: 100px;
    align-self: center;
    transform: rotate(16deg);

    @media (min-width: 750px) {
      transform: scaleX(-1) rotate(95deg);
    }
  }
`

export const ProfileCardContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;  
  padding: 0px 30px;
  h1 {
     font-size: 18px;
     margin: 30px 0px 0px 0px;
     color: #0179b4;
    
  }
  @media (min-width: 750px) {
    h1 {
    width: 80%;
    font-size: 24px;
  }
  }

`

export const FormIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  width: 70%;
  @media (min-width: 750px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
  }
`
