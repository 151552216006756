import React from 'react'
import styled from 'styled-components'
import Medley from '../assets/medley_vit.png'

const MedleyImg = styled.img`
  width: 50px;
  height: 43px;

  @media (min-width: 700px) {
    width: 70px;
    height: 63px;
  }
`

export const Logo = () => {
  return <MedleyImg src={Medley} alt='logo' />
}
