import React from 'react'
import styled from 'styled-components'

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-right: 150px;
  p {
    color: #0179b4;
    font-weight: 600;
  }
`

export const LottieLoading = () => {
  return (
    <LoadingContainer>
      <p>Skickar mejl...</p>
    </LoadingContainer>
  )
}
