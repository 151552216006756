import React from 'react'
import styled from 'styled-components'
import { ProfileCard } from '../components/reusable/ProfileCard'
import {
  GreySection,
  SecondInnerContainer,
  ProfileCardContainer,
} from '../components/reusable/StyledComponents'

const MiddleTitle = styled.h3`
  color: #0179b4;
  margin: 0;
  align-self: start;
  font-size: 20px;

  @media (min-width: 750px) {
    font-size: 24px;
  }
`

const AboutInnerContainer = styled.div`
  width: 100%;
  padding: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media (min-width: 750px) {
    width: 90%;
  }
`

export const About = () => {
  return (
    <GreySection>
      <SecondInnerContainer>
        <ProfileCardContainer>
          <ProfileCard />
        </ProfileCardContainer>

        <AboutInnerContainer>
          <p>
            Fysioterapimottagningen för dig med såväl akuta som långvariga
            smärtbesvär eller skada. Med ambition att alltid erbjuda
            individanpassad och evidensbaserad rehabilitering för en aktiv och
            hälsosam livsstil.
          </p>
          <br />
          <br />
          <MiddleTitle>
            Spetskompetens inom kvinnohälsa och bäckenbottenbesvär.
          </MiddleTitle>
          <br />
          <br />
          <p>
          Din fysioterapimottagning i Linköping med inriktning och spetskompetens inom kvinnohälsa. 
          Med erfarenhet inom specialiserad kvinnohälsovård erbjuder jag stöd och behandling till kvinnor i alla livsskeden.
          <br/>
          <br/> 
          Jag arbetar med allt från graviditetsrelaterade besvär och återhämtning efter förlossning till bedömning och behandling av bäckenbottenproblematik, inkontinens och klimakteriebesvär. Med fördjupad kunskap inom kvinnors hälsa erbjuds du grundlig bedömning och behandling, alltid baserat på dina förutsättningar och mål.
          </p>
        </AboutInnerContainer>
      </SecondInnerContainer>
    </GreySection>
  )
}
